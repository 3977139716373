<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import util from "../../util/date";
import echarts from 'echarts'
import {GetPeopleProcessNumberByTodayLogData} from '../api/oa'
require('echarts/theme/macarons') // echarts theme
import resize from '../views/Dashboard/admin/components/mixins/resize'

const animationDuration = 2000

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '330px'
    },
    departid: {
       type: Array,
       default: []
    }
  },
  data() {
    return {
      lookdate:new Date(),
      chart: null,
      departusers: [],
      peoplecode: [],
      listone: [],
      listtwo: [],
      listthree: [],
      listfour: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
    var lookdate=this.$parent.$parent.getLookDate();
    // console.log(yearVal)
    this.lookdate=lookdate;
    //console.log(this.year);
    var valuelist = new Array()
    this.departid.forEach(item => {
      
        valuelist.push(item.UserNameClaim);
        this.peoplecode.push("BPM/"+item.UserName);
        if(this.peoplecode.length == 1){
          GetPeopleProcessNumberByTodayLogData({peoples:this.peoplecode,date:util.formatDate.format(this.lookdate,'yyyy/MM/dd')}).then(res => {
              this.listone = res.data.response[0];
              this.listtwo = res.data.response[1];
              this.initChart()
          })
        }
    })
    this.departusers = valuelist;
    this.initChart()
            
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.chart.off('click')
      let self=this;
      this.chart.on('click', data=>{
        // console.log(data);
        var index=data.dataIndex;
        // console.log(this.peoplecode);
        var usercode=this.peoplecode[index];
        // console.log(usercode);
        // console.log(self.$parent);
        self.$emit('call',usercode);
      });
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: 10,
          left: '2%',
          right: '2%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: this.departusers,
          axisTick: {
            alignWithLabel: true
          },
          axisLabel:{
            interval: 0,
    	 }
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          }
        }],
        series: [{
          name: '发起数量',
          type: 'bar',
          stack: 'vistors',
          barWidth : 40,
          data: this.listone,
          animationDuration
        }, {
          name: '处理数量',
          type: 'bar',
          stack: 'vistors',
          barWidth : 40,
          data: this.listtwo,
          animationDuration
        }]
      });

      
    }
  }
}
</script>
