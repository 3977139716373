<template>
    <section style="background: #FFFFFF;">
        <!--组织展示-->
        <el-col :span="4">
             <div class="orgbtndivtree">
                <el-divider>部门</el-divider>
                <el-cascader :props="props" v-model="cascadervalue" ref="department" 
                clearable size="mini" :show-all-levels="false" 
                expand-trigger="hover" popper-class="depc"
                @change="cascaderchange"></el-cascader>
            </div>
            <div class="orgtree">
             <!--中间列表-->
                <el-table
                    :data="departusers"
                    highlight-current-row
                    :row-style="{height:'40px'}"
                    :cell-style="{padding:'0px'}"
                    empty-text="请选择部门"
                    :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                    
                    >
                    <el-table-column type="index" width="70px"></el-table-column>
                    <el-table-column label="姓名" prop="UserNameClaim" align="left"></el-table-column>
                    <!-- <el-table-column label="部门" prop="DepartmentName" align="center"></el-table-column> -->
                </el-table>
             <!-- 分页器 -->
            <el-pagination v-if="Pagination" align='center' @current-change="handleCurrentChangeDepart" :current-page="DepartcurrentPage" :page-size="DepartpageSize" layout="prev, pager, next" :page-count="Departtotal" :total="Departtotaldata">
            </el-pagination>
         </div>
        </el-col>
        <!--工作数据展示-->
        <el-col :span="20">
            <div>
                <span style="color:#999" >选择查看日期：</span>
                <el-date-picker v-model="lookdate" type="date" @change="handleDateChange" :clearable="false" placeholder="选择查看日期"></el-date-picker>
                <el-divider>流程概览分析</el-divider>
            </div>
            <div v-show="isdepartment">
                <!-- <RaddarCharttool :key="key" :departid = "departid"></RaddarCharttool> -->
                <span style="font-size: 12px;color: red;">当天各人员流程操作数量占比</span>
                <PeopleTodayProcessLogBarCharttool @call="call($event)" :key="key" :departid = "departid"></PeopleTodayProcessLogBarCharttool>
                <!-- <span style="font-size: 12px;color: red;">各人员任务执行进度占比</span>
                <BarCharttool :key="key" :departid = "departid"></BarCharttool>
                <span style="font-size: 12px;color: red;">各人员任务完成日期及数量占比</span>
                <PeopleTaskBarCharttool :key="key" :departid = "departid"></PeopleTaskBarCharttool>
                 -->

                <el-table
                    :data="users" 
                    highlight-current-row
                    row-key = "Id"
                    lazy
                    :indent="30"
                    :load = "load"
                    :tree-props = "{children: 'children',hasChildren: 'IsHasChildren'}"
                    :fit="true"
                    :row-style="{height:'40px'}"
                    :cell-style="{padding:'0px'}"
                    :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                    
                    
                    @row-dblclick="checkInfo"
                    style="width: 100%;height:100%;">
                        <el-table-column type = "index"></el-table-column>
                        <el-table-column label="实例号" prop="INCIDENT" min-width="60px" show-overflow-tooltip></el-table-column>
                        <el-table-column label="流程名称" prop="PROCESSNAME" min-width="100px" show-overflow-tooltip></el-table-column>
                        <el-table-column label="处理时间" prop="ENDTIME" min-width="60px" :formatter = "formatCreateTime">
                            <template slot-scope="scope">
                                <el-tooltip placement="top">
                                    <div slot="content">
                                        {{ formatTipEndTime( scope.row) }}
                                    </div>
                                    <div>
                                        {{ formatEndTime( scope.row) }}
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column label="节点" prop="STEPLABEL" min-width="170px" show-overflow-tooltip></el-table-column>
                    </el-table>
                    <!-- 分页器 -->
                    <el-pagination align='center' @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper" :page-count="total" :total="totaldata">
                    </el-pagination>
                    
            </div>
        </el-col>
    </section>
</template>
<script>
import util from '../../../util/date';
import PeopleTodayProcessLogBarCharttool from "../../components/PeopleTodayProcessLogBarCharttool"
import {GetChildrenList,QueryPageTodayOperatorProcessByUsercode,QueryTasksById,QueryTaskProgressByTaskId,QueryChildTasksByParentId,GetUserTopDepartmentList,GetDepartmentPostUserList,GetYearPlanListByUserCode} from "../../api/oa"

export default {
     components: { PeopleTodayProcessLogBarCharttool },
    data(){
        return{
            lookdate:new Date(),
            Pagination: true,
            currentPage: 1, // 当前页码
            total: null, // 总条数
            totaldata:null,
            pageSize: 1500, // 每页的数据条数
            page: 1,
            DepartcurrentPage: 1, // 当前页码
            Departtotal: null, // 总条数
            Departtotaldata:null,
            DepartpageSize:  20, // 每页的数据条数
            Departpage: 1,
            departmentsearchdata: [],
            TypeIdModules: [],   //部门类型集合
            users: [],
            departusers: [],
            departmentList: [],
            DptProps: {
                label: 'Name',
                isLeaf: 'Leaf'
            },
            currentRow: null,
            DepartUserRow: null,
            workItem: null,
            taskClassify: 0,
            taskStatus: 0,
            progressValue: null,
            deptinput: null,
            madedate: null,
            cascadervalue: null,
            ClassifyModules:[
                {
                    value: 0,
                    label: "全部"
                },
                {
                    value: 1,
                    label: "日常工作"
                },
                {
                    value: 2,
                    label: "跨部门协调"
                },
                {
                    value: 3,
                    label: "例会项目"
                },
                {
                    value: 4,
                    label: "年度计划"
                },
                {
                    value: 5,
                    label: "专项会议"
                },
            ],
            StatusModules: [
                {
                    value: 0,
                    label: "全部"
                },
                {
                    value: 1,
                    label: "进行中"
                },
                // {
                //     value: 1,
                //     label: "已完成审核中"
                // },
                {
                    value: 2,
                    label: "已完成"
                },
                // {
                //     value: 3,
                //     label: "退回"
                // },
                // {
                //     value: 4,
                //     label: "新建任务待审核"
                // },
                // {
                //     value: 5,
                //     label: "取消任务待审核"
                // },
                // {
                //     value: 6,
                //     label: "新建任务退回" 
                // },
               
            ],
            checkVisible: false,
            checkForm: {            //查看表单初始化
                Id: null,
                WorkItem: null,
                WorkDescription: null,
                PlanComplateTime: null,
                Remark: null,
                IsNeedApproval: null
            },
            showAssign: false,
            createPerson: false,                    //创建人是否显示
            showyearplan: false,                    //年度计划查看界面显示控制
            showorganize: false,                     //跨部门协同查看界面显示控制
            showregularproject: false,               //例会来源查看界面控制
            showprofessionmeetong: false,            //专项会议查看界面控制
            CommonTaskClassifyText: null,
            pValue1: null,   //进度条数值
            istaskpro: false,
            tasks: [],
            options: [],
            checkdepartid: null,
            departuserid: null,
            props: {
                checkStrictly: true,
                lazy: true,
                lazyLoad (node, resolve) {
                    const { level,value } = node;
                    if(node.level == 0){
                        var user = JSON.parse(window.localStorage.user);
                        GetUserTopDepartmentList({userId: user.sub}).then(res => {
                                const nodes = res.data.data.map(x=>({
                                    value: x.deptId,
                                    label: x.deptName,
                                    leaf: x.hasNode
                                }))
                                resolve(nodes)                         
                        });
                    }else{
                        GetChildrenList({departmentId: node.value}).then(res => {
                            const nodes = res.data.response.map(x=>({
                                    value: x.Id,
                                    label: x.Name,
                                    leaf: x.Leaf
                                }))
                                resolve(nodes)
                        })
                    }
                    
                }
            },
            YearPlanTaskList: [],
            YearPlanTask: null,
            YearPlanTaskId: null,
            isdepartment: false,
            departid: [],
            key: 0,
            userid: '',
            isuser: false
        }
    },
    methods:{
        getLookDate:function()
        {
            return this.lookdate;
        },
        handleDateChange()
        {
            // console.log(this.lookdate)
            if(this.checkdepartid!=null)
            {
                this.getDepartUsers(this.checkdepartid);
            }
        },
        call(usercode)
        {
            let para = {
                userCode: usercode,
                date:this.lookdate,
                pageIndex: this.page,
                pageSize: this.pageSize
            };
            QueryPageTodayOperatorProcessByUsercode(para).then((res) => {
                // console.log(res)
                 this.total = res.data.response.pageCount;
                 this.totaldata = res.data.response.dataCount;
                 this.users = res.data.response.data;
            });
        },
       //双击查看详细信息
     checkInfo(row){
        //  console.log(row)
          this.checkVisible = true;
        if(row.TaskClassify ==1){  
            // this.CommonTaskClassifyText = row.CommonTaskClassifyText;
            this.CommonTaskClassifyText = "日常工作";
                this.showorganize = false;
                this.showyearplan = false;
                this.showregularproject = false;
                this.showprofessionmeetong = false;
                if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                }else{
                   this.showAssign = false; 
                }
                this.createPerson = true;
        }else{
                if(row.TaskClassify ==2)
                {
                    this.CommonTaskClassifyText = "跨部门协调";
                    this.showorganize = true;
                    this.showyearplan = false;
                    this.showregularproject = false;
                    this.showprofessionmeetong = false;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = false;
                }
                else if(row.TaskClassify ==3)
                {
                    this.CommonTaskClassifyText =  "例会项目";
                    this.showorganize = false;
                    this.showyearplan = false;
                    this.showregularproject = true;
                    this.showprofessionmeetong = false;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = true;
                }else if(row.TaskClassify ==4)
                {
                    this.CommonTaskClassifyText =  "年度计划";
                    this.showorganize = false;
                    this.showyearplan = true;
                    this.showregularproject = false;
                    this.showprofessionmeetong = false;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = false;
                }else if(row.TaskClassify ==5){
                    this.CommonTaskClassifyText = "专项会议";
                    this.showorganize = false;
                    this.showyearplan = false;
                    this.showregularproject = false;
                    this.showprofessionmeetong = true;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = true;
                }
                else {
                    this.CommonTaskClassifyText =  "其他";
                    this.showorganize = false;
                    this.showyearplan = false;
                    this.showregularproject = false;
                    this.showprofessionmeetong = false;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = false;
                }
            }
            QueryTasksById({Id: row.Id,taskClassify: row.TaskClassify}).then((res) => {
                this.checkForm = res.data.response;
            })
          this.pValue1 = row.TaskProgressValue;
        //   this.checkForm = Object.assign({},row);
          let para = {taskId: row.Id};
          QueryTaskProgressByTaskId(para).then((res) => {
              this.tasks = res.data.response;
          });
        },
        selectDepartUserRow(val){
            this.DepartUserRow = val;
            this.departuserid = val.UserId;
            this.YearPlanTaskId = null;
            this.getManagerChecks();
            let para = {userCode: val.UserId};
            // GetYearPlanListByUserCode(para).then((res) => {
            //     this.YearPlanTaskList = res.data.response;
            // });
            // this.isuser = true;
            // this.isdepartment = false;
            // this.userid = val.UserId;
            // this.key += 1;
        },
        YearPlanchange(val){
            this.YearPlanTask = val.split('|')[2];
            this.YearPlanTaskId = val.split('|')[1];
        },
        load(tree,treeNode,resolve){
            let para = {
                taskId: tree.Id,
            };
            QueryChildTasksByParentId(para).then((res) => {
                resolve(res.data.response)
            });
        },
        formatPlanComplateTime: function (row,colume) {
            return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime),'yyyy-MM-dd');
        },
        formatCreatedOn: function (row,colume) {
                return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn),'yyyy-MM-dd hh:mm');
            },
        formatCommonTaskClassifyText: function(row,colume){
                if(row.TaskClassify ==1){  
                    // return row.CommonTaskClassifyText;
                    return "日常工作";
                }else{
                    if(row.TaskClassify ==2)
                    {
                        return "跨部门协调";
                    }
                    else if(row.TaskClassify ==3)
                    {
                        return "例会项目";
                    }else if(row.TaskClassify ==4)
                    {
                        return "年度计划";
                    }else if(row.TaskClassify ==5){
                        return "专项会议";
                    }
                    else {
                        return "其他";
                    }
                }
            },
        formatSource: function (row,column) {
                return row.ParentId != null ? '被指派' : row.ParentId == null ? '新建' : '未知';
            },
        formatCreateTime: function(row,column) {
                return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
            },
        formatTipCreateTime: function (row, column) {
                return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime),'yyyy-MM-dd hh:mm');
            },

        formatTipEndTime: function (row, column) {
                return (!row.ENDTIME || row.ENDTIME == '') ? '' : util.formatDate.format(new Date(row.ENDTIME),'yyyy-MM-dd hh:mm');
            },
        formatEndTime: function (row, column) {
                return (!row.ENDTIME || row.ENDTIME == '') ? '' : util.formatDate.dateformat(new Date(row.ENDTIME));
            },
        //翻页处理
        handleCurrentChange(val) {
           this.page = val;
           this.getManagerChecks();
           this.page = 1;
      },
      getManagerChecks(){
           //时间转化
             let begintime = "";  //开始时间
             let endtime = "";    //结束时间
            if(this.madedate != null){
                switch (this.madedate.length) {
                    case 1:
                    begintime += util.formatDate.format(new Date(this.madedate[0]), 'yyyy-MM-dd');
                    break;
                    case 2:
                    begintime += util.formatDate.format(new Date(this.madedate[0]), 'yyyy-MM-dd');
                    endtime += util.formatDate.format(new Date(this.madedate[1]), 'yyyy-MM-dd');
                    break;
                    default:
                    break;
                }    
            }
            if(this.departuserid == null){
                 this.$message({
                    message: '请先选择想要查看任务的部门人员',
                    type: 'warning',
                    duration: 7000
                });
                return;
            }
            let para = {
                userCode: this.departuserid,
                workItem: this.workItem,
                taskClassify: this.taskClassify,
                taskStatus: this.taskStatus,
                begin: begintime,
                end: endtime,
                yearplanId: this.YearPlanTaskId,
                progressValue: this.progressValue,
                pageIndex: this.page,
                pageSize: this.pageSize
            };
            QueryPageTodayOperatorTasksByUsercode(para).then((res) => {
                 this.total = res.data.response.pageCount;
                 this.totaldata = res.data.response.dataCount;
                 this.users = res.data.response.data;
            });
      },
        cascaderchange(node){
            // 目的是选择之后将下拉界面收起
            this.$refs.department.toggleDropDownVisible();
            this.Pagination = false;
            let pathvalue = this.$refs.department.getCheckedNodes()[0];
            this.checkdepartid = pathvalue.value;
            this.getDepartUsers(pathvalue.value);
            this.isdepartment = true;
            this.isuser = false;
            // this.key += 1;
            this.$refs.department.dropDownVisible = false;
        },
        handleCurrentChangeDepart(val){
           this.Departpage = val;
           this.getDepartUsers(this.checkdepartid);
           this.Departpage = 1;
        //    this.key += 1;
        },
        //获取部门人员
        getDepartUsers(node){
            let para = {
                deptId: node,
                isAllNextDept:false,
                pageNum: this.Departpage,
                pageSize: this.DepartpageSize
            }
            GetDepartmentPostUserList(para).then(res => {
                var data=res.data.data.list;
                let newdata=this.unique(data);
                this.departusers = newdata;
                this.Pagination = true;
                this.departid = newdata;
                // this.departid = res.data.response.data;
                this.key += 1;
                this.Departtotal = res.data.data.pages;
                this.Departtotaldata = res.data.data.total;
            });

        },
        unique(arr) {
            const res = new Map();
            return arr.filter((arr) => !res.has(arr.UserId) && res.set(arr.UserId, 1));
        }

    },
    mounted(){
        
    }
}
</script>
<style scoped>
.orgtree {
  /* overflow-y: auto;
  overflow-x: scroll; */
  height: calc(100vh - 280px);
  width:200px;
  border: 0px solid blue;
  padding-top: 35px;
}
.orgbtndivtree {
  padding-left: 20px;
  padding-right: 20px;
  
}
/deep/.el-table__body tr.current-row>td {
  background: #FABE64 !important;
}
/deep/input:disabled,/deep/textarea:disabled{
    opacity: 1;
    -webkit-text-fill-color: rgb(12, 12, 12);
}
.managercheckdiv {
 height: 600px;
}
#managercheckcard{
    height: 600px;
}
.tasksdiv {
    max-height: 400px;
    overflow: auto;
  padding-left: 2px;
}
/deep/.el-table .warning-row {
    background: #F0F8FF;
}
/deep/.el-button--goon {
  color: #FFF;
  background-color: #20B2AA;
  border-color: #20B2AA;
}
/deep/.el-button--goon:hover {
  background: #48D1CC;
  border-color: #48D1CC;
  color: #fff;
}
/deep/.el-button--goon:focus {
  background: #20B2AA;
  border-color: #20B2AA;
  color: #fff;
}
</style>